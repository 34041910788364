import { useNavigate } from 'react-router-dom';
import { ButtonBase } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import {ReactComponent as CheckIcon} from '../../assets/icons/check-circle2.svg'
import {ReactComponent as UncheckIcon} from '../../assets/icons/uncheck-circle2.svg'

import {ReactComponent as FilterIcon} from '../../assets/icons/filter-icon.svg';
import {ReactComponent as EditIcon} from '../../assets/icons/edit-icon.svg';
import {ReactComponent as UpVoteOnIcon} from '../../assets/icons/up-vote-on.svg';
import {ReactComponent as UpVoteOffIcon} from '../../assets/icons/up-vote-off.svg';
import {ReactComponent as DownVoteOnIcon} from '../../assets/icons/down-vote-on.svg';
import {ReactComponent as DownVoteOffIcon} from '../../assets/icons/down-vote-off.svg';
import CustomImage from '../CommonComponents/CustomImage';
import CustomButton from '../CommonComponents/CustomButton';
import api from '../../api/apiClient';
import { endpoints } from '../../api/endpoints';
import { SongRequestsDataType, SongRequestType } from '../../types';
import { formatNumber } from '../../utils';
import InfiniteScrollWrapper from '../CommonComponents/InfinityScrollWrapper';
import { useStore } from '../../store';
import Loader from '../CommonComponents/Loader';
import Footer from '../CommonComponents/Footer';
import CustomDrawer from '../CommonComponents/CustomDrawer';
import TransitionWrapper from '../CommonComponents/TransitionWrapper';

export default function SongRequests() {
  const {store} = useStore();
  const text = store.text;
  const [data, setData] = useState<SongRequestsDataType>();
  const [hasMore,setHasMore] = useState(true);
  const [page,setPage] = useState(1);
  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);
  const [filterValues, setFilterValues] = useState([
    { value: "topVote", label: "Top Vote"},
    { value: "myRequest", label: "My Requests"},
    { value: "oldest", label: "Oldest"},
    { value: "newest", label: "Newest"},
  ]);
  const [selectedFilterValue, setSelectedFilterValue] = useState(filterValues[0].value);

  const firstMounted = useRef(true);
  const navigate = useNavigate();

  const handleVote = async (currentVote: string, songRequestId: number) => {
    if(data?.by){
        let newRef: SongRequestsDataType = JSON.parse(JSON.stringify(data));
        let copy = newRef?.by;
        const index = copy.findIndex((item) => item.id === songRequestId);
        const preVote = copy[index].user_vote;
        if(preVote === null){
            if(currentVote === 'up'){
                copy[index].up.count = copy[index].up.count + 1
            }else{
                copy[index].down.count = copy[index].down.count + 1
            }
            copy[index].user_vote = currentVote;
        }else{
            if((preVote === 'up' && currentVote === 'up') || (preVote === 'down' && currentVote === 'down')){
                if(currentVote === 'up'){
                    copy[index].up.count = copy[index].up.count - 1
                }else{
                    copy[index].down.count = copy[index].down.count - 1
                }
                copy[index].user_vote = null;
            }else{
                if(preVote === 'up'){
                    copy[index].up.count = copy[index].up.count - 1;
                    copy[index].down.count = copy[index].down.count + 1;
                }else{
                    copy[index].down.count = copy[index].down.count - 1
                    copy[index].up.count = copy[index].up.count + 1
                }
                copy[index].user_vote = currentVote;
            }
        }
        setData(newRef);
    }
    let params: any = {
        postId: songRequestId
    };
    if(currentVote === 'up'){
        params.vote = 'up';
    }else{
        params.vote = 'down';
    }
    await api.post(endpoints.songRequest.vote, params);
  }

  const handleFilterChange = (filterValue: string) => {
    if(selectedFilterValue === filterValue) return;
    setSelectedFilterValue(filterValue);
    setFilterDrawerOpen(false);
    setPage(1);
    setHasMore(true);
  }
 
  const renderHeader = () => {
    return (
        <div style={{display: "flex", justifyContent: 'space-between', alignItems: 'center'}}>
            <h3 style={{fontSize: "25px", color: 'white'}}>{(text as any)[filterValues.filter(v => v.value === selectedFilterValue)[0].label]}</h3>
            <div>
                <ButtonBase onClick={() => setFilterDrawerOpen(true)}>
                    <FilterIcon style={{width: "20px", height: "18px",marginRight: "20px"}}/>
                </ButtonBase>
                <ButtonBase style={{width: "25px", height: "25px"}} onClick={() => navigate('/songRequests/add')}>
                    <EditIcon/>
                </ButtonBase>
            </div>
        </div>
    )
  }

  const songRequestCard = (s: SongRequestType) => {
    const handleContribute = () => {
        if(s.song.status !== "Contribute") return;
        navigate('/dataEntry', {state: {songId: s.song.id, songName: s.song.name, artistId: s.song.artist.artist_id, artistName: s.song.artist.name}});
    }
    return (
        <div style={{marginBottom: "33px"}}>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <CustomImage src={s.user.image} width={'32px'} height={'32px'} borderRadius={'20px'}/>
                <div style={{display: 'flex', flexDirection: "column",marginLeft: '10px'}}>
                    <h3 style={{color: 'white', fontSize: "15px", margin: 0}}>{s.user.name}</h3>
                    <h3 style={{fontSize: "10px",color: '#707070',margin: 0}}>{s.requestedAt}</h3>
                </div>
            </div>
            <h3 style={{color: 'white', fontSize: "25px",marginTop: "10px",marginBottom: '5px'}}>{s.song.name}</h3>
            <h3 style={{color: 'white', fontSize: '15px',marginTop: 0}}>{s.song.artist.name}</h3>
            <h3 style={{color: "#B3B3B3", fontSize: "13px"}}>{s.description}</h3>
            <div style={{display: "flex", justifyContent: "space-between"}}>
                <div style={{display: "flex"}}>
                    <div style={{display: 'flex', alignItems: "center", marginRight: "30px"}}>
                        <ButtonBase style={{width: "25px", height: "25px", marginRight: "5px"}} onClick={() => handleVote('up',s.id)}>
                            {s.user_vote === 'up' ? <UpVoteOnIcon/> : <UpVoteOffIcon/>}
                        </ButtonBase>
                        <h3 style={{fontSize: '17px', color: '#868686'}}>{formatNumber(s.up.count)}</h3>
                    </div>
                    <div style={{display: 'flex', alignItems: "center"}}>
                        <ButtonBase style={{width: "25px", height: "25px",marginRight: "5px"}} onClick={() => handleVote('down',s.id)}>
                            {s.user_vote === 'down' ? <DownVoteOnIcon/> : <DownVoteOffIcon/>}
                        </ButtonBase>
                        <h3 style={{fontSize: '17px', color: '#868686'}}>{formatNumber(s.down.count)}</h3>
                    </div>
                </div>
                <CustomButton onClick={handleContribute} style={{backgroundColor: "transparent", border: "1px solid #878787",paddingLeft: "30px", paddingRight: "30px"}} active={false}>{s.song.status}</CustomButton>
            </div>
        </div>
    )
  }

  const fetchData = async () => {
    const result: any = await api.get(endpoints.songRequest.getSongRequests + `?current=${page}&limit=10&filter=${selectedFilterValue}`);
    if(result.code === '200'){
      const d : SongRequestsDataType = result.data;
      setData((pre) => {
        let obj: any = {...pre};
        let arr : any;
        if(page > 1){
          if(pre && d) arr = [...pre?.by,...d?.by]
          obj.by = arr;
          return obj
        }else{
          return d
        }
      });
    }else{
      if(page > 1){
        setHasMore(false);
      }else{
        setHasMore(false);
        setData(undefined);
      }
    }
  }

  useEffect(() => {
    if(firstMounted.current) return;
    if(data?.by?.length === data?.pagination?.total) setHasMore(false);
  },[data])

  const handleFetchMore = () => {
    setPage(pre => pre + 1)
  }

  useEffect(() => {
    fetchData();
  },[page, selectedFilterValue])

  useEffect(() => {
    if(data?.by && data?.pagination){
        if(data?.by?.length === data?.pagination?.total) setHasMore(false);
    }
  },[data])

  useEffect(()=> {
    if(firstMounted.current) firstMounted.current = false;
    fetchData();
  },[]);


  return (
    <div style={{padding: "0 17px"}}>
        <TransitionWrapper>
            <FilterDrawer
                filterValues={filterValues}
                handleFilterChange={handleFilterChange}
                isOpen={filterDrawerOpen}
                onClose={() => setFilterDrawerOpen(false)}
                selectedfilterValue={selectedFilterValue}
            />
            {renderHeader()}
            <InfiniteScrollWrapper
                dataLength={data?.by.length || 0}
                fetchMoreData={handleFetchMore}
                hasMore={hasMore}
                height={store.screenHeight - 80}
                loader={<Loader btn/>}
            >
                <div>
                    {data && data?.by.map(s => {
                        return (
                            <div key={s.id} style={{marginBottom: "33px"}}>
                                {songRequestCard(s)}
                            </div>
                        )
                    })}
                </div>
            </InfiniteScrollWrapper>
            
        </TransitionWrapper>
        <Footer/>
    </div>
  )
}

interface filterDrawerProps {
    isOpen: boolean;
    onClose: () => void;
    filterValues: {value: string, label: string}[]
    selectedfilterValue: string,
    handleFilterChange: (value: string) => void;
}

const FilterDrawer: React.FC<filterDrawerProps> = ({ isOpen, onClose, filterValues, selectedfilterValue, handleFilterChange }) => {
    const {store} = useStore();
    const text = store.text;

  
    return (
      <div>
          <CustomDrawer isOpen={isOpen} onClose={onClose} outsideInteraction={false}>
                <h3 style={{color: "#909090", fontSize: "20px",marginLeft: '30px'}}>Filter</h3>
                {
                    filterValues.map(f => {
                        return(
                            <ButtonBase onClick={() => handleFilterChange(f.value)}  style={{display: 'flex',justifyContent: "space-between", alignItems: "center",padding: '0 10px',margin: "20px 30px 20px 30px",borderWidth: "0 0 2px 0", borderColor: "#242424",borderStyle: 'solid'}}>
                                <h3 style={{fontSize: "15px"}}>{(text as any)[f.label]}</h3>
                                {f.value === selectedfilterValue ?  <CheckIcon/> : <UncheckIcon/>}
                            </ButtonBase>
                        )
                    })
                }
          </CustomDrawer>
      </div>
    );
  };
