/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Form, message, Modal } from 'antd';

import {ReactComponent as StarIcon} from '../../assets/icons/star.svg';
import {ReactComponent as SongIcon} from '../../assets/icons/song.svg';
import {ReactComponent as CheckedIcon} from '../../assets/icons/check-circle.svg';
import {ReactComponent as UncheckedIcon} from '../../assets/icons/uncheck-circle.svg';
import {ReactComponent as PlusCircleIcon} from '../../assets/icons/plus-circle-on.svg';
import {ReactComponent as UpVoteOnIcon} from '../../assets/icons/up-vote-on.svg';
import {ReactComponent as UpVoteOffIcon} from '../../assets/icons/up-vote-off.svg';
import {ReactComponent as DownVoteOnIcon} from '../../assets/icons/down-vote-on.svg';
import {ReactComponent as DownVoteOffIcon} from '../../assets/icons/down-vote-off.svg';
import {ReactComponent as CloseIcon} from '../../assets/icons/close.svg';
import BackHeader from '../CommonComponents/BackHeader'
import CustomSelect from '../CommonComponents/CustomSelect';
import { useStore } from '../../store';
import api from '../../api/apiClient';
import { endpoints } from '../../api/endpoints';
import { ArtistType, SongRequestsDataType, SongRequestType, SongType } from '../../types';
import { useEffect, useState } from 'react';
import CustomButton from '../CommonComponents/CustomButton';
import CustomAutocompleteInput from '../CommonComponents/CustomAutocompleteInput';
import CustomTextArea from '../CommonComponents/CustomTextArea';
import CustomPhotoUpload from '../CommonComponents/CustomPhotoUpload';
import CustomRadio from "../CommonComponents/CustomRadio";
import { ButtonBase } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CustomImage from "../CommonComponents/CustomImage";
import { formatNumber } from "../../utils";

export default function AddSongRequest() {

  const [artists, setArtists] = useState<{value: string,label: string}[]>([]);
  const [songs, setSongs] = useState<{value: string,label: string}[]>([]);
  const [gender, setGender] = useState("");
  const [loading, setLoading]  = useState(false);
  const [addArtistMode, setAddArtistMode] = useState(false);
  const [form] = Form.useForm();
  const {store} = useStore();

  const navigate = useNavigate();

  const text = store.text;

  const handleArtistSearch = async (v: string = "") => {
    form.setFieldValue('artistName',v);
    const result: any = await api.get(endpoints.artist.search + `&keyword=${v}&current=1&limit=5`)
    if(result.code === '200'){
      const formattedArr = result.data?.by?.map((a : ArtistType) => ({value: a?.artist_id, label: a?.name}));
      setArtists(formattedArr)
    }else{
      setArtists([])
    }
  }

  const handleSongSearch = async (v: string = "") => {
    const result: any = await api.get(endpoints.song.search + `&keyword=${v}&current=1&limit=5`)
    if(result.code === '200'){
      const formattedArr = result.data?.by?.map((s : SongType) => ({value: s?.name, label: s?.name}));
      setSongs(formattedArr)
    }else{
      setSongs([])
    }
  };

  const handleNewSongRequestFinish = async (values: any) => {
    const {songName, singerId, description, artistName, artistImg, artistType, artistIds} = values;
    console.log('values',values);
    if(!description) return message.info(text['Please fill description!']);
    const existingSong = songs.findIndex(s => s.value === songName);
    if(existingSong !== -1) return message.info(text["Song exist!"]);
    const existingSinger = artists.findIndex(t => t.label === artistName);
    if(existingSinger !== -1) return message.info("Artist exist!");
    setLoading(true);
    const result: any = await api.post(endpoints.songRequest.addSongRequest, {name: songName, artistId: singerId, description, artistName, artistImg, artistType, artistIds});
    setLoading(false);
    if(result.code === '200'){
      if(result.data){
        const id = result.data.id;
        const res: any = await api.get(endpoints.songRequest.getSongRequests + `?current=${1}&limit=10&filter=${id}`);
        if(res.code !== "200") return;
        const data: SongRequestsDataType = res.data;
        showExistingSongRequest(data.by[0]);
        return;
      }
      navigate(-1);
      message.success(result.message);
      form.resetFields();
    }else{
      message.error(result.message);
    }
  }

  useEffect(() => {
    handleArtistSearch();
    return () => {
      Modal.destroyAll();
    }
  }, [])

  const showExistingSongRequest = (s: SongRequestType) => {
    return Modal.confirm({
      title: text['Song request already exist'],
      content: <SongRequestCard s={s}/>,
      okButtonProps: {style: {display: 'none'}},
      cancelButtonProps: {style: {display: 'none'}},
      centered: true,
      closable: true,
      closeIcon: <CloseIcon/>
    });
  }

  const SongRequestCard = ({s}: {s: SongRequestType}) => {
    const [data, setData] = useState(s);
    const handleContribute = () => {
        if(s.song.status !== "Contribute") return;
        Modal.destroyAll();
        navigate('/dataEntry', {state: {songId: s.song.id, songName: s.song.name, artistId: s.song.artist.artist_id, artistName: s.song.artist.name}});
    }

    const handleVote = async (currentVote: string, songRequestId: number) => {
      if(data){
          let newRef: SongRequestType = JSON.parse(JSON.stringify(data));
          const preVote = newRef.user_vote;
          if(preVote === null){
              if(currentVote === 'up'){
                  newRef.up.count = newRef.up.count + 1
              }else{
                  newRef.down.count = newRef.down.count + 1
              }
              newRef.user_vote = currentVote;
          }else{
              if((preVote === 'up' && currentVote === 'up') || (preVote === 'down' && currentVote === 'down')){
                  if(currentVote === 'up'){
                      newRef.up.count = newRef.up.count - 1
                  }else{
                      newRef.down.count = newRef.down.count - 1
                  }
                  newRef.user_vote = null;
              }else{
                  if(preVote === 'up'){
                      newRef.up.count = newRef.up.count - 1;
                      newRef.down.count = newRef.down.count + 1;
                  }else{
                      newRef.down.count = newRef.down.count - 1
                      newRef.up.count = newRef.up.count + 1
                  }
                  newRef.user_vote = currentVote;
              }
          }
          console.log("newRef", newRef)
          setData(newRef);
      }
      let params: any = {
          postId: songRequestId
      };
      if(currentVote === 'up'){
          params.vote = 'up';
      }else{
          params.vote = 'down';
      }
      await api.post(endpoints.songRequest.vote, params);
    }

    return (
        <div style={{marginTop: "1rem"}}>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <CustomImage src={data.user.image} width={'32px'} height={'32px'} borderRadius={'20px'}/>
                <div style={{display: 'flex', flexDirection: "column",marginLeft: '10px'}}>
                    <h3 style={{color: 'white', fontSize: "15px", margin: 0}}>{data.user.name}</h3>
                    <h3 style={{fontSize: "10px",color: '#707070',margin: 0}}>{data.requestedAt}</h3>
                </div>
            </div>
            <h3 style={{color: 'white', fontSize: "25px",marginTop: "10px",marginBottom: '5px'}}>{data.song.name}</h3>
            <h3 style={{color: 'white', fontSize: '15px',marginTop: 0}}>{data.song.artist.name}</h3>
            <h3 style={{color: "#B3B3B3", fontSize: "13px"}}>{data.description}</h3>
            <div style={{display: "flex", justifyContent: "space-between"}}>
                <div style={{display: "flex"}}>
                    <div style={{display: 'flex', alignItems: "center", marginRight: "30px"}}>
                        <ButtonBase style={{width: "25px", height: "25px", marginRight: "5px"}} onClick={() => handleVote('up',data.id)}>
                            {data.user_vote === 'up' ? <UpVoteOnIcon/> : <UpVoteOffIcon/>}
                        </ButtonBase>
                        <h3 style={{fontSize: '17px', color: '#868686'}}>{formatNumber(data.up.count)}</h3>
                    </div>
                    <div style={{display: 'flex', alignItems: "center"}}>
                        <ButtonBase style={{width: "25px", height: "25px",marginRight: "5px"}} onClick={() => handleVote('down',data.id)}>
                            {data.user_vote === 'down' ? <DownVoteOnIcon/> : <DownVoteOffIcon/>}
                        </ButtonBase>
                        <h3 style={{fontSize: '17px', color: '#868686'}}>{formatNumber(data.down.count)}</h3>
                    </div>
                </div>
                <CustomButton onClick={handleContribute} style={{backgroundColor: "transparent", border: "1px solid #878787",paddingLeft: "30px", paddingRight: "30px"}} active={false}>{data.song.status}</CustomButton>
            </div>
        </div>
    )
  }

  return (
    <div>
      <div>
        <BackHeader/>
        <h1 style={{position: 'fixed', top: 0, left: "50%",transform: 'translate(-50%, 0)',color: "white", fontSize: "22px"}}>
          {text['Song Request']}
        </h1>
      </div>
      <div className="scrollable-y remove-scrollbar" style={{height: store.screenHeight }}>
        <Form
          name="basic"
          form={form}
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          style={{
            fontFamily: "inherit",
            padding: '0 15px'
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={handleNewSongRequestFinish}
          autoComplete="off"
        >
          <Form.Item
              name="songName"
              wrapperCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: `! Song Name is required`,
                },
                {
                  validator: (_, value) =>
                    value && value.length > 35
                      ? Promise.reject(new Error('Song Name is too long'))
                      : Promise.resolve(),
                },
              ]}
              style={{ padding: '', color: 'white', margin: '20px 0 35px 0' }}
            >
              <CustomAutocompleteInput
                placeholder={text['Song Name']}
                options={songs}
                onSearch={handleSongSearch}
                prefix={<SongIcon />}
                canSelectAutoCompleteValue={false}
              />
          </Form.Item>
          {!addArtistMode && <Form.Item
            name="singerId"
            wrapperCol = {{span: 24}}
            rules={[
              {
                required: true,
                message: `! Singer is required`,
              },
            ]}
            style={{color: "white",margin: "35px 0"}}
          >
            <CustomSelect
              size='large'
              showSearch
              filterOption={false}
              suffixIcon={<></>}
              prefixIcon={<StarIcon />}
              placeholder={text.Singer}
              onSearch={handleArtistSearch}
              options={artists}
              notFoundContent={
                <div style={{display: 'flex', justifyContent: "space-between", alignItems: "center"}} onClick={()=> setAddArtistMode(true)}>
                  <h3 style={{color: 'white'}}>{text['Add new singer']}</h3>
                    <PlusCircleIcon />
                </div>
              }
              onChange={(v)=> {
                const singerName = artists.filter((a : any) => a.value === v)[0]?.label;
                form.setFieldValue('singerName',singerName);
              }}
            />
          </Form.Item>}
          {
            addArtistMode &&
            <div>
              <Form.Item
                name="artistName"
                wrapperCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: `!Artist Name is required`,
                  },
                  {
                    validator: (_, value) =>
                      value && value.length > 35
                        ? Promise.reject(new Error('Artist Name is too long'))
                        : Promise.resolve(),
                  },
                ]}
                style={{ padding: '', color: 'white', margin: '20px 0 35px 0' }}
              >
                <CustomAutocompleteInput
                  placeholder={text['Singer Name']}
                  options={artists}
                  onSearch={handleArtistSearch}
                  prefix={<StarIcon />}
                  canSelectAutoCompleteValue={false}
                />
              </Form.Item>
              <div style={{border: "1px solid #202020",borderRadius: "20px", position: "relative"}}>
                <ButtonBase style={{width: "30px", height: "30px",position: "absolute", top: '10px', right: '10px', zIndex: 1000}}
                  onClick={(e)=> {
                    e.stopPropagation();
                    form.resetFields();
                    setAddArtistMode(false)
                  }}
                >
                  <CloseIcon/>
                </ButtonBase>
                <Form.Item
                  name="artistImg"
                  wrapperCol = {{span: 24}}
                  style={{padding: "",color: "white",margin: "20px 0 0 0",width: "100%",display: 'flex', justifyContent: "center"}}
                  css={css`
                    .ant-upload-wrapper{
                      width: 100% !important;
                    }
                    .ant-upload-select{
                      width: 100% !important;
                    }
                ` }
                >
                  <CustomPhotoUpload text={text["Add photo"]} alwaysDisplayUploadOutline={true}/>
                </Form.Item>

                {gender === 'group' && <Form.Item
                name="artistIds"
                wrapperCol = {{span: 24}}
                rules={[
                  {
                    required: true,
                    message: `!At least one artist is required`,
                  },
                ]}
                style={{color: "white",margin: "35px 10px"}}
              >
                <CustomSelect
                  size='large'
                  showSearch
                  filterOption={false}
                  suffixIcon={<></>} 
                  prefixIcon={<StarIcon />}
                  placeholder={text["Group Singers"]}
                  onSearch={handleArtistSearch}
                  options={artists}
                  mode="multiple"
                  multiSelect={true}
                  onChange={(v)=> {
                    
                  }}
                />
              </Form.Item>}

                <Form.Item
                  name="artistType"
                  wrapperCol = {{span: 24}}
                  rules={[
                    {
                      required: true,
                      message: `! Singer is required`,
                    },
                  ]}
                  style={{color: "white",margin: "10px 0 35px 0"}}
                  initialValue={"male"} // value will be lower letter
                >
                  <CustomRadio onChange={(v) => setGender(v)} options={[
                      { value: 'male', label: text.Male },
                      { value: 'female', label: text.Female },
                      { value: 'group', label: text.Group },
                      { value: 'composer', label: text.Composer }
                  ]} checkedIcon={<CheckedIcon width={'30px'}/>} uncheckedIcon={<UncheckedIcon width={'30px'}/>}/>
                </Form.Item>
              </div>
            </div>
          }
          <Form.Item
            name="description"
            wrapperCol = {{span: 24}}
            style={{color: "white",margin: "35px 0 18px 0"}}
            rules={[
              {
                validator: (_, value) =>
                  value && value.length > 300
                    ? Promise.reject(new Error('Description is too long'))
                    : Promise.resolve(),
              },
            ]}
          >
            <CustomTextArea upperText={text["Description"]} autoSize={{ minRows: 5, maxRows: 8}}  />
          </Form.Item>
          <div style={{display: "flex",justifyContent: "center"}}>
            <CustomButton loading={loading} active={true} type='submit'>
              {text['Request']}
            </CustomButton>
          </div>
        </Form>
      </div>
    </div>
  )
}
