/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { KeyboardEvent, MouseEventHandler, UIEvent, useEffect, useRef, useState } from 'react';
import {  DndContext, UniqueIdentifier, useDraggable, useDroppable} from '@dnd-kit/core';
import { message } from 'antd';

import { useStore } from '../../store';
import {ReactComponent as Tag} from '../../assets/icons/tag2.svg';
import {ReactComponent as TransposerIcon} from '../../assets/icons/setting.svg';
import useDoubleClick from '../CommonComponents/useDoubleClick';
import { customSplit, isEnglishWord } from '../../utils';
import ChordAddDrawer from './ChordAddDrawer';
import { ButtonBase } from '@mui/material';
import TransposerDrawer from './TransposerDrawer';

type DndComponentType = {
  lyrics: string[],
  chordPositions: Record<string, string>,
  handleLyricsChange: React.Dispatch<React.SetStateAction<string[]>>,
  handleChordPositionsChange: React.Dispatch<React.SetStateAction<Record<string, string>>>
  handleTransposerChange: (level: string) => void;
}

const DndComponent: React.FC<DndComponentType> = ({lyrics, chordPositions, handleLyricsChange, handleChordPositionsChange, handleTransposerChange}) => {
  const {store, showAlert} = useStore();
  const [isChordDrawerOpen, setIsChordDrawerOpen] = useState(false);
  const [isTransposeDrawerOpen, setIsTransposeDrawerOpen] = useState(false);

  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  const [isDragging, setIsDragging] = useState(false);

  const [chords, setChords] = useState<string[]>([]);
  const firstMounted = useRef<boolean>(false);
  const defaultChords = ['C', 'G', 'Am', 'F', 'D', 'Em', 'A', 'E', 'Dm', 'Bm', 'Bb', 'G7', 'D7', 'A7', 'E7'];

  useEffect(()=> {
    if(!firstMounted.current){
      firstMounted.current = true;
      setChords(defaultChords);
      return;
    }
    localStorage.setItem('chords',JSON.stringify(chords));
  },[chords])

  useEffect(()=> {
    const c = localStorage.getItem('chords');
    if(c) {
      setChords(JSON.parse(c));
    }
  },[])

  const updateChordPositionsDueToNewLine = (chordPositions: Record<string, string>, newLineIndex: number): void => {
    const updatedChordPositions: Record<string, string> = {};
  
    Object.keys(chordPositions).forEach((key) => {
      const [lineIndex,wordIndex] = key.split('_')[1].split('-');
      const currentLineIndex = parseInt(lineIndex, 10);

      // Update line index if it is greater than or equal to newLineIndex
      const updatedLineIndex = currentLineIndex >= newLineIndex ? currentLineIndex + 1 : currentLineIndex;
  
      // Create new key with updated line index
      updatedChordPositions[`word_${updatedLineIndex}-${wordIndex}`] = chordPositions[key];
    });
    handleChordPositionsChange(updatedChordPositions)
  };

  const deleteChordsInLine = (chordPositions: Record<string, string>, lineIndexToDelete: number): void => {
    const updatedChordPositions: Record<string, string> = {};
  
    Object.keys(chordPositions).forEach((key) => {
      const [lineIndex, wordIndex] = key.split('_')[1].split('-');
      const currentLineIndex = parseInt(lineIndex, 10);
      
      // Only add chords that are not in the line to delete
      if (currentLineIndex !== lineIndexToDelete) {
        const updatedLineIndex = currentLineIndex > lineIndexToDelete ? currentLineIndex - 1 : currentLineIndex;
        updatedChordPositions[`word_${updatedLineIndex}-${wordIndex}`] = chordPositions[key];
      }
    });
  
    handleChordPositionsChange(updatedChordPositions);
  };

  const handleDrop = (event: any) => {
    const { active, over } = event;
    setIsDragging(false);
    setActiveId(null);
    let dragItem = active?.id?.split('_')[0];
    let dropItem = over?.id?.split('_')[0];
    let o = checkCorrectOver(active, over);

    if (o) {
      if (dragItem === 'newLine') {
        const newLineIndex = Number(over.id.split('_')[1]);
        setTimeout(()=> {
          document.getElementById('input-line-'+newLineIndex)?.focus();
        },500)
        handleLyricsChange((prev) => {
          const updatedLyrics = [...prev];
          updatedLyrics.splice(newLineIndex, 0, '()');
          updateChordPositionsDueToNewLine(chordPositions,newLineIndex);
          return updatedLyrics;
        });
      } else {
        const chord = active.id.split("_")[2];
        const overId = over.id;

        const chordBox = document.getElementById(`box-${overId}`);
        if (chordBox!.querySelector('.chord')) {
          return;
        }

        let prePos = active.id.split("_")[4];
        if (prePos) prePos = `${active.id.split("_")[3]}_${active.id.split("_")[4]}`;

        handleChordPositionsChange((prev) => {
          const updatedPositions = { ...prev };
          if (prePos && updatedPositions[prePos] === chord) {
            delete updatedPositions[prePos];
          }
          updatedPositions[overId] = chord;
          // console.log("updatedPosition", updatedPositions);
          return updatedPositions;
        });
      }
    }
  };

  // Callback function to update chords
  const handleDeleteDraggableChord = (chordToDelete: string) => {
    setChords((prevChords) => prevChords.filter(chord => chord !== chordToDelete));
  };

  const handleDeleteEditableChord  = (chordToDelete: string, containerId: string) => {
    handleChordPositionsChange((pre)=> {
      const updatedPositions = { ...pre };
      delete updatedPositions[containerId];
      return updatedPositions;
    });
  };

  const handleDeleteDroppableLine  = (lineIndex: number) => {
    handleLyricsChange((prev) => {
      const updatedLyrics = [...prev];
      updatedLyrics.splice(lineIndex, 1);
      deleteChordsInLine(chordPositions,lineIndex);
      return updatedLyrics
    }
  )
  };

  const handleTitleSubmit = (index: number, event: KeyboardEvent<HTMLInputElement>) => {
    const { value } = event.target as HTMLInputElement

    if (event.key !== 'Enter') return
    const invalidCharacters = /[ (){}]/;

    if (value.length > 6) {
      message.info("Too long, Must be within 6 words")
      return
    }
  
    if (invalidCharacters.test(value)) {
      message.info("Input cannot contain spaces or the characters (, ), {, }.");
      return;
    }
    
    handleLyricsChange((prev) => {
      const updatedLyrics = [...prev];
      updatedLyrics[index] = `(${value})` + " ".repeat(22);
      return updatedLyrics;
    });
  };

  const handleOpenChordDrawer = () => {
    setIsChordDrawerOpen(true);
  };

  const handleCloseChordDrawer = () => {
    setIsChordDrawerOpen(false);
  };

  const handleAddChord = (chord: string) => {
    if(chords.length > 14) return message.info("Chords are full!");
    if(chords.includes(chord)) return message.info("Chord already exist!");
    chords.push(chord);
    setChords([...chords]);
    handleCloseChordDrawer();
  }

  const handleTransposer = (level: string) => {
    setIsTransposeDrawerOpen(false);
    handleTransposerChange(level)
  }

  const headerHeight = 80;

  return (
    <div >
      <ChordAddDrawer isOpen={isChordDrawerOpen} onClose={handleCloseChordDrawer} handleAddChord={handleAddChord} />
      <TransposerDrawer isOpen={isTransposeDrawerOpen} onClose={() => setIsTransposeDrawerOpen(false)} handleTransposerChange={handleTransposer} />
      <ButtonBase onClick={() => setIsTransposeDrawerOpen(true)}  style={{width: "45px",height: "45px",backgroundColor: "#3373EF",display: 'flex',justifyContent: "center",alignItems: "center",borderRadius: "30px",position: "fixed",bottom: "100px",right: "30px",zIndex: 1000}}>
        <TransposerIcon/>
      </ButtonBase>
      <DndContext   
        autoScroll={false}
        onDragEnd={handleDrop}  
        onDragStart={({active}) => {
          setActiveId(active.id);
          setIsDragging(true);
        }}
      >
        <div className={`${isDragging? 'scrollable-hidden' : 'scrollable-y'} remove-scrollbar`} style={{height: store.screenHeight - headerHeight + "px"}}>
          <NewLineIcon />
          <ChordBox chords={chords} handleDeleteDraggableChord={handleDeleteDraggableChord} handleOpenChordDrawer={handleOpenChordDrawer}/>
          
          <div style={{paddingTop: '200px',textAlign: "center", whiteSpace: "nowrap"}}>
            {lyrics.map((line, lineIndex) => (
              <div key={lineIndex} >
                {(
                  <DroppableLine id={`separator_${lineIndex}`} />
                )}
                {isTagLine(line) ? (
                    <TagLine 
                      activeId={activeId} 
                      chordPositions={chordPositions} 
                      handleDeleteDroppableLine={handleDeleteDroppableLine} 
                      handleDeleteEditableChord={handleDeleteEditableChord} 
                      handleTitleSubmit={handleTitleSubmit} 
                      line={line} 
                      lineIndex={lineIndex} 
                      key={lineIndex}
                    />
                  ) : (
                  customSplit(line).map((word, wordIndex) => (
                    <DroppableWord
                      key={`word_${lineIndex}-${wordIndex}`}
                      id={`word_${lineIndex}-${wordIndex}`}
                      word={word}
                      line={line}
                      chord={chordPositions[`word_${lineIndex}-${wordIndex}`]}
                      onChordDelete={handleDeleteEditableChord}
                      activeId={activeId}
                    />
                  ))
                )}
              </div>
            ))}
          </div>
        </div>
      </DndContext>
    </div>
  );
};

interface TagLineProps {
  line: string,
  lineIndex: number,
  handleTitleSubmit: (lineIndex: number, e: KeyboardEvent<HTMLInputElement>) =>  void,
  chordPositions: Record<string,string>,
  handleDeleteDroppableLine: (lineToDelete: number) => void
  handleDeleteEditableChord: (chordToDelete: string, containerId: string) => void
  activeId: UniqueIdentifier | null
}
const TagLine : React.FC<TagLineProps> = ({line, lineIndex, handleTitleSubmit, chordPositions, handleDeleteDroppableLine, handleDeleteEditableChord, activeId }) => {
  return (
    <div style={{ paddingBottom: '4px',position: 'relative' }}>
      {!containsTitleInParentheses(line) ? (
        <input
          id={`input-line-${lineIndex}`}
          style={{
            color: "#3373EF",
            backgroundColor: "#1A1A1A",
            outline: "none",
            borderWidth: "0px",
            textAlign: "center",
            fontSize: "16px"
          }}
          onKeyDown={(e) => handleTitleSubmit(lineIndex, e)}
        />
      ) : (
        <div style={{marginLeft: "25px"}}>
          {customSplit(line).map((word, wordIndex) => (
            word.startsWith('(') ?
            <TitleComponent key={wordIndex} lineIndex={lineIndex}  title={getTitleInsideParentheses(line)} onDelete={handleDeleteDroppableLine}/>
            :
            <DroppableWord
              key={`word_${lineIndex}-${wordIndex}`}
              id={`word_${lineIndex}-${wordIndex}`}
              word={word}
              line={line}
              chord={chordPositions[`word_${lineIndex}-${wordIndex}`]}
              onChordDelete={handleDeleteEditableChord}
              activeId={activeId}
              tagLine={true}
            />

           ))}
        </div>
      )}
    </div>
  )
}


interface ChordBox {
  chords: string[],
  handleDeleteDraggableChord: (chordToDelete: string) => void,
  handleOpenChordDrawer: MouseEventHandler<HTMLHeadingElement>
}

const ChordBox : React.FC<ChordBox> = ({chords, handleDeleteDraggableChord, handleOpenChordDrawer}) => {
   let width;
   if (window.innerWidth <= 480) {
    width = '92vw';
  } else if (window.innerWidth > 480 && window.innerWidth <= 768) {
    width = '65vw';
  } else if (window.innerWidth > 768 && window.innerWidth <= 1024) {
    width = '47vw';
  } else {
    width = '30vw';
  }
   return(
    <div style={{position: "fixed", zIndex: 1000, width: '350px', transform: 'translate(-50%)',left: '50%'}}>
      <div  style={{
        display: "flex",
        flexWrap: 'wrap',
        justifyContent: 'start',
        alignItems: "flex-start",
        alignContent: "flex-start",
        backgroundColor: '#1a1a1a',
        borderRadius: '8px',
        border: '1px solid #333',
        height: "144px",
        width: '100%'
        }}>
        {chords.map((chord) => (
          <DraggableChord key={chord} chord={chord} onDelete={handleDeleteDraggableChord} />
        ))}
      </div>
      <div style={{cursor: "pointer",width: "100%",color: '#3373EF',textAlign: "end"}}>
        <h5 style={{display: 'inline-block', fontSize: '10px', marginTop: "5px"}} onClick={handleOpenChordDrawer}>Edit Chords</h5>
      </div>
    </div>
   )
}

interface TitleComponentProps {
  title: string;
  lineIndex: number;
  onDelete: (lineToDelete: number) => void
}
const TitleComponent: React.FC<TitleComponentProps> = ({ title, lineIndex, onDelete }) => {
  const [showDeleteUI, setShowDeleteUI] = useState(false);

  const chordRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (chordRef.current && !chordRef.current.contains(event.target as Node)) { //checks whether the click event occurred outside the DraggableChord component.
      setShowDeleteUI(false);
    }
  };

  useEffect(() => {
    if (showDeleteUI) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showDeleteUI]);


  const handleDoubleClick = () => {
    console.log("double clicked: ")
    setShowDeleteUI(true);
  };

  const handleClick = useDoubleClick(handleDoubleClick);

  const handleDeleteClick = () => {
    onDelete(lineIndex);
    setShowDeleteUI(false);
  };

    return (
        <div ref={chordRef} >
          <div onMouseDown={handleClick} style={{marginRight: "10px",cursor: 'pointer',display: 'flex',justifyContent: "center",alignItems: "center",color: '#3373EF', position: 'absolute', left: '5px', bottom: '-10px', padding: '0 8px', transform: 'translateY(-50%)'}}>
              <Tag style={{marginRight: "5px"}}/>
              {showDeleteUI && (
                <div style={{
                  position: 'absolute',
                  bottom: '100%', 
                  marginBottom: "10px",
                  left: '10%',
                  transform: 'translateX(-50%)',
                  backgroundColor: '#FFFFFF',
                  color: '#FF0000',
                  padding: '5px 10px',
                  borderRadius: '10px',
                  zIndex: 100,
                  whiteSpace: 'nowrap',
                  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                }}>
                  <div  onClick={handleDeleteClick} style={{ userSelect: "none",position: 'relative', textAlign: 'center', fontWeight: 'bold', fontSize: '12px', cursor: 'pointer' }}>
                    Delete
                  </div>
                  <div style={{
                    position: 'absolute',
                    top: '100%',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    width: '0',
                    height: '0',
                    borderLeft: '10px solid transparent',
                    borderRight: '10px solid transparent',
                    borderTop: '10px solid #FFFFFF',
                  }}></div>
                </div>
              )}
              
              <div style={{textAlign: "start",userSelect: 'none'}}>
                <div>{title}</div>
                <div style={{marginTop: "5px", width: "300px",height: "1px",backgroundColor: "#414141"}}></div>
              </div>
          </div>
        </div>
    )
};


interface DraggableChordProps {
  chord: string;
  onDelete: (chordToDelete: string) => void;
}
const DraggableChord: React.FC<DraggableChordProps> = React.memo(({ chord, onDelete }) => {
  const [showDeleteUI, setShowDeleteUI] = useState(false);

  const chordRef = useRef<HTMLDivElement>(null);

  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: `chord_newChord_${chord}`,
    disabled: showDeleteUI,

  });

  const style = {
    transform: `translate3d(${transform?.x ?? 0}px, ${transform?.y ?? 0}px, 0)`,
    width: "45px",
    height: "24px",
    margin: "13px 10px 0 10px",
    borderRadius: "12px",
    border: showDeleteUI ? "2px solid #FF0000" : "2px solid #FFB800",
    backgroundColor: "#000000",
    color: showDeleteUI ? "#FF0000" : "#FFB800",
    fontSize: "10px",
    fontWeight: "bold",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    touchAction: "none",
    cursor: "pointer"
  } as React.CSSProperties;

  const handleClickOutside = (event: MouseEvent) => {
    if (chordRef.current && !chordRef.current.contains(event.target as Node)) { //checks whether the click event occurred outside the DraggableChord component.
      setShowDeleteUI(false);
    }
  };

  useEffect(() => {
    if (showDeleteUI) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showDeleteUI]);


  const handleDoubleClick = () => {
    setShowDeleteUI(true);
  };

  const handleClick = useDoubleClick(handleDoubleClick);

  const handleDeleteClick = () => {
    onDelete(chord);
    setShowDeleteUI(false);
  };

  return (
    <div ref={chordRef} style={{position: 'relative'}}>
      <div onMouseDown={handleClick}  ref={setNodeRef}  {...listeners}  {...attributes} style={style}>
        {chord}
      </div>
      {showDeleteUI && (
        <div style={{
          position: 'absolute',
          bottom: '100%', // Position above the chord
          left: '50%',
          transform: 'translateX(-50%)',
          backgroundColor: '#FFFFFF',
          color: '#FF0000',
          padding: '5px 10px',
          borderRadius: '10px',
          zIndex: 100,
          whiteSpace: 'nowrap',
          boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
        }}>
          <div onClick={handleDeleteClick} style={{ position: 'relative', textAlign: 'center', fontWeight: 'bold', fontSize: '12px', cursor: 'pointer' }}>
            Delete
          </div>
          <div style={{
            position: 'absolute',
            top: '100%',
            left: '50%',
            transform: 'translateX(-50%)',
            width: '0',
            height: '0',
            borderLeft: '10px solid transparent',
            borderRight: '10px solid transparent',
            borderTop: '10px solid #FFFFFF',
          }}></div>
        </div>
      )}
    </div>
  );
});

interface EditableChordProps {
  chord: string;
  containerId: string;
  onDelete: (chordToDelete: string, containerId: string) => void;
}
const EditableChord: React.FC<EditableChordProps> = ({ chord, containerId, onDelete }) => {
  const [showDeleteUI, setShowDeleteUI] = useState(false);

  const chordRef = useRef<HTMLDivElement>(null);

  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: `chord_droppedChord_${chord}_${containerId}`,
    disabled: showDeleteUI
  });

  const style = {
    fontSize: '12px',
    // fontSize: '18px',
    transform: `translate3d(${transform?.x ?? 0}px, ${transform?.y ?? 0}px, 0)`,
    touchAction: 'none',
    color: showDeleteUI ? "#FF0000" : "#FFB800",
    fontWeight: "bold",
    cursor: 'pointer',
    zIndex: 1000,
    position: "fixed",
    top: 0,
    right: 0,
  } as React.CSSProperties;

  const handleClickOutside = (event: MouseEvent) => {
    if (chordRef.current && !chordRef.current.contains(event.target as Node)) { //checks whether the click event occurred outside the DraggableChord component.
      setShowDeleteUI(false);
    }
  };

  useEffect(() => {
    if (showDeleteUI) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showDeleteUI]);


  const handleDoubleClick = () => {
    setShowDeleteUI(true);
  };

  const handleClick = useDoubleClick(handleDoubleClick);

  const handleDeleteClick = () => {
    onDelete(chord,containerId);
    setShowDeleteUI(false);
  };

  return (
    <div ref={chordRef} style={{position: 'relative'}}>
      <div onMouseDown={handleClick} ref={setNodeRef} {...listeners} {...attributes} style={style} className="chord" >
        {chord}
      </div>
      {showDeleteUI && (
        <div style={{
          position: 'absolute',
          bottom: '10%', 
          left: '50%',
          transform: 'translateX(-50%)',
          backgroundColor: '#FFFFFF',
          color: '#FF0000',
          padding: '5px 10px',
          marginBottom: "10px",
          borderRadius: '10px',
          zIndex: 100,
          whiteSpace: 'nowrap',
          boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
        }}>
          <div onClick={handleDeleteClick} style={{ position: 'relative', textAlign: 'center', fontWeight: 'bold', fontSize: '12px', cursor: 'pointer' }}>
            Delete
          </div>
          <div style={{
            position: 'absolute',
            top: '100%',
            left: '50%',
            transform: 'translateX(-50%)',
            width: '0',
            height: '0',
            borderLeft: '10px solid transparent',
            borderRight: '10px solid transparent',
            borderTop: '10px solid #FFFFFF',
          }}></div>
        </div>
      )}
    </div>
  );
};

interface DroppableWordProps {
  word: string;
  line: string;
  id: string;
  chord?: string;
  onChordDelete : (chordToDelete: string, containerId: string) => void;
  activeId: UniqueIdentifier | null;
  tagLine?: boolean;
}
const DroppableWord: React.FC<DroppableWordProps> = ({ word, line, id, chord, onChordDelete, activeId, tagLine = false }) => {
  const { setNodeRef, active, over, isOver } = useDroppable({
    id,
    disabled: activeId === "newLine"
  });
 
  let o;
  if (isOver) {
    o = checkCorrectOver(active, over);
  }

  const style = {
    display: 'inline-block',
    margin: '0px',
    position: 'relative',
    color: "white",
    borderLeft: o ? '2px solid white' : 'none', // Display cursor line when dragging over
    fontSize: "16px",
    userSelect: "none",
    fontFamily: 'Arial, sans-serif'
  } as React.CSSProperties;

  const emptySpace = tagLine ? '' : '5px';

  const offset = isTagLine(line)? '10px' : isEnglishWord(word) ? '-21.5px' : '-15px';

  return (
    <span  style={style}>
      <div ref={setNodeRef} id={`box-${id}`} className="chord-box" style={{ position: 'absolute', top: offset, left: '50%', transform: 'translateX(-50%)', width: '10px', height: '10px',zIndex: 1}}>
        {chord && (
          <EditableChord key={`${id}-${chord}`} chord={chord} containerId={id} onDelete={onChordDelete} />
        )}
      </div>
      {word === " " ? <div css={css`font-family: 'Arial, sans-serif' !important;`} style={{ color: "transparent", width: emptySpace}}>င</div> : word}
    </span>
  );
};

interface DroppableLineProps {
  id: string;
  onDrop?: (event: any) => void;
}
const DroppableLine: React.FC<DroppableLineProps> = ({ id }) => {
  const { setNodeRef, isOver, active, over } = useDroppable({
    id
  });

  let o = false;
  if (isOver) {
    o = checkCorrectOver(active, over)
  }

  const style = {
    height: '4px',
    background: o ? '#414141' : "",
    margin: '8px 0',
    position: 'relative',
  } as React.CSSProperties;

  return (
    <div ref={setNodeRef} style={style} />
  );
};

const NewLineIcon: React.FC = () => {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: 'newLine',
  });

  const style = {
    transform: `translate3d(${transform?.x ?? 0}px, ${transform?.y ?? 0}px, 0)`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: 'pointer',
    touchAction: 'none',
    position: "absolute",
    top: "82px",
    right: "150px",
    zIndex: 2000
  } as React.CSSProperties;

  return (
    <div ref={setNodeRef} {...listeners} {...attributes} style={style}>
        <Tag style={{marginRight: "20px",cursor: "pointer"}}/>
    </div>
  );
};

const checkCorrectOver = (active: any, over: any) => {
  let dragItem = active?.id?.split('_')[0];
  let dropItem = over?.id?.split('_')[0];
  let o = false;
  if (dragItem === 'newLine' && dropItem === 'separator') {
    o = true;
  } else if (dragItem === 'chord' && dropItem === 'word') {
    o = true;
  }
  return o;
}

const isTagLine = (line: string) => {
  return line.startsWith('(');
}

const  containsTitleInParentheses = (str: string) => {
  const regex = /^\(.+?\)/;
  return regex.test(str);
}

const getTitleInsideParentheses = (line: string) => {
  const match = line.match(/\(([^)]+)\)/);
  return match ? match[1] : '';
};

export default DndComponent;
