/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { useState, ChangeEvent, useRef, UIEvent, KeyboardEvent } from 'react';
import { useStore } from '../../store';
import { message } from 'antd';
import parse from 'html-react-parser';

type LyricsAreaProps =  {
  lyrics: string,
  highLightedLyrics: string,
  handleLyricsChange: Function,
  isUserOwnSong: boolean
}

const LyricsArea = ({lyrics, handleLyricsChange, highLightedLyrics, isUserOwnSong} : LyricsAreaProps) => {
  const {store} = useStore();

  // const headerHeight = isUserOwnSong ? 220 : 75;
  const headerHeight = 85;

  const styles = {
    textarea: {
      width: '95%',
      height: store.screenHeight - headerHeight + "px",
      padding: '10px 10px 0 10px',
      // marginBottom: "10px",
      fontSize: '16px',
      fontFamily: 'Arial, sans-serif',
      color: "white",
      backgroundColor: "#1A1A1A",
      borderWidth: 0,
      textAlign: "center",
      lineHeight: '50px',
      outline: "none",
      whiteSpace: 'nowrap'
    } as React.CSSProperties,
  };

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    handleLyricsChange(event.target.value);
  };

  const test = useRef<HTMLDivElement | null>(null);

  const handleScroll = (event: UIEvent<HTMLTextAreaElement>) => {
    const scrollTop = event.currentTarget.scrollTop;
    const scrollLeft = event.currentTarget.scrollLeft;
    if(test.current){
      test.current.scrollTop = scrollTop;
      test.current.scrollLeft = scrollLeft;
    }
    
  }

  const handleKeyDown = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    const specialCharacters = ['(', ')', '{', '}'];
    
    if (specialCharacters.includes(event.key)) {
      event.preventDefault();
      message.info(`The character '${event.key}' is not allowed.`);
    } 
  };

  return (
      <div  style={{transform: "translateZ(0)"}}>
        <div style={{width: '100%',position: 'absolute',zIndex: 1,pointerEvents: "none"}}>
          <div ref={test} className="remove-scrollbar"
           css={css` 
            font-family: sans-serif !important;
           `}
           style={{...styles.textarea,
            backgroundColor: 'transparent',
            color: 'transparent',
            whiteSpace: "pre",
            overflowX: "scroll",
            overflowY: "scroll",
          }}>
            { parse(highLightedLyrics) }
          </div>
        </div>
        <textarea
          css={css` 
            font-family: sans-serif !important;
          `}
          className='remove-scrollbar'
          style={styles.textarea}
          value={lyrics}
          onScroll={handleScroll}
          onChange={handleChange}
          placeholder="Enter your lyrics here..."
          spellCheck="false"
          onKeyDown={handleKeyDown}
        />
      </div>
  );
};



export default LyricsArea;



