import React, { useState } from 'react'
import BackHeader from '../CommonComponents/BackHeader'
import { useLocation } from 'react-router-dom'
import CustomSelect from '../CommonComponents/CustomSelect';
import CustomInput from '../CommonComponents/CustomInput';
import CustomButton from '../CommonComponents/CustomButton';
import api from '../../api/apiClient';
import { endpoints } from '../../api/endpoints';
import { message } from 'antd';
import { addCommaToNumber } from '../../utils';
import { useStore } from '../../store';

export default function ExchangePoints() {
  const location = useLocation();
  const {store} = useStore();
  const text = store.text;
  const [selectedAmount, setSelectedAmount] = useState<number>(1000);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<string>('kbzPay');
  const [accountName, setAccountName] = useState<string>('');
  const [accountNumber, setAccountNumber] = useState<string>('');
  const [points, setPoints] = useState<number>(location?.state?.points || 0);

  const [loading, setLoading] = useState(false);

  const pointsOptions = [1000, 5000, 10000, 50000, 100000, 500000];
  const paymentMethods = [
    {value: "kbzPay",label: "KBZ PAY"},
    {value: "wavePay",label: "WAVE PAY"},
  ];

  const handleExchange = async () => {
    if(selectedAmount > points){
        return message.info("You don't have enough points");
    }
    
    if(!accountName){
        return message.info("Please enter account name");
    }
    if(!accountNumber){
        return message.info("Please enter account number");
    }

    setLoading(true);
    const params = {
        points: selectedAmount,
        paymentType: selectedPaymentMethod,
        paymentAccount: accountNumber,
        paymentAccountName: accountName
    }
    const result : any = await api.post(endpoints.points.withdraw, params);
    setLoading(false);
    if(result.code === "200") {
        setPoints(pre => pre - selectedAmount);
        setAccountName("");
        setAccountNumber("");
        return message.success(result.message);
    };
    message.error(result.message);
  }

  return (
    <div>
        <BackHeader/>
        <div style={{padding: "0 20px"}}>
            <div>
                <h2 style={{ fontSize: '20px',marginBottom: 0,color: '#909090'}}>{text['Total Points']}</h2>
                <h1 style={{fontSize: '50px',marginBottom: '20px', color: "white",marginTop: 0}}>{addCommaToNumber(points || 0)}</h1>
            </div>
            <h3 style={{fontSize: '16px',marginBottom: '10px',color: "#909090"}}>1. {text['Choose Amount']}</h3>
            <div style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: "space-evenly",
                gap: '10px',
                marginBottom: '50px',
            }}>
                {pointsOptions.map(amount => (
                <button
                    key={amount}
                    onClick={() => setSelectedAmount(amount)}
                    style={{
                    backgroundColor: 'transparent',
                    color: 'white',
                    border: selectedAmount === amount ? '3px solid #3373EF' : '1px solid #333333',
                    cursor: 'pointer',
                    fontSize: "20px",
                    width: "100px",
                    height: "60px"
                    }}
                >
                    {amount.toLocaleString()}
                </button>
                ))}
            </div>
            <h3 style={{fontSize: '16px',marginBottom: '10px',color: "#909090"}}>2. {text['Choose Payment Method']}</h3>
            <CustomSelect
                size='large'
                options={paymentMethods}
                value={selectedPaymentMethod}
                onChange={(v)=> setSelectedPaymentMethod(v)}
                style={{width: "100%"}}
            />
            <CustomInput value={accountName} onChange={(v)=> setAccountName(v.target.value)} customStyle={{marginTop: "20px", height: "52px"}} placeholder={"Account Name"} size='large' />
            <CustomInput value={accountNumber} onChange={(v)=> setAccountNumber(v.target.value)} customStyle={{marginTop: "20px", height: "52px"}} placeholder={"Account Number"} size='large'  />
            <div style={{display: "flex", justifyContent: "center"}}>
                <CustomButton loading={loading} style={{marginTop: "50px", }} onClick={handleExchange} active>Exchange</CustomButton>
            </div>
        </div>
    </div>
  )
}
