// SlideTransitionWrapper.tsx
import React, { useContext } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useRouteTracker } from './RouteTracker';

interface TransitionWrapperProps {
  children: React.ReactNode;
  duration?: number;
}

const TransitionWrapper: React.FC<TransitionWrapperProps> = ({ children, duration = 0.3 }) => {
  let { previousLocation, currentLocation } = useRouteTracker();

  previousLocation = previousLocation?.split('/')[1] as string;
  currentLocation = currentLocation?.split('/')[1] as string;

  const pages = ['home', 'songRequests', 'dataEntry', 'library', 'profile'];

  // Determine the direction based on the page indices
  // const currentIndex = pages.indexOf(currentLocation);
  // const previousIndex = pages.indexOf(previousLocation || currentLocation);
  // const direction = currentIndex > previousIndex ? 1 : -1;

//   const initObj =  {x: direction > 0 ? '-100%' : '100%' }
//   const animateObj =  { x: 0 };
//   const exitObj = { x: '-100%' };

  const initObj =  {opacity: 0 }
  const animateObj =  { opacity: 1};
  const exitObj = { opacity: 0.5 };


  return (
    <AnimatePresence>
      <motion.div
        // key={currentLocation}
        // initial={{ x: direction > 0 ? '100%' : '-100%' }}
        // animate={{ x: 0 }}
        // exit={{ x: direction > 0 ? '-100%' : '100%' }}
        // transition={{ duration }}
        initial={initObj}   
        animate={animateObj}          
        exit={exitObj}       
        transition={{ duration }} 
      >
        {children}
      </motion.div>
    </AnimatePresence>
  );
};

export default TransitionWrapper;
