import React, { ReactNode, memo, useContext, useEffect, useRef, useState } from 'react';

import { storeType } from './types';
import Alert from './components/CommonComponents/Alert';
import unicodeJson from './languages/unicode.json';
import enJson from './languages/en.json';
import PlaylistDrawer from './components/HomeComponents/PlaylistDrawer';

interface StoreProviderProps {
    children: ReactNode;
}

export const storeContext = React.createContext({});

type AlertType = 'error' | 'info' | 'success';

export const StoreProvider : React.FC<StoreProviderProps>  = memo(({ children }) => {
    const footerHeight = 60;
    const [store,updateStore] = useState<storeType['store']>({
        currentPage: "/" + window.location.pathname.split('/')[1],
        text: unicodeJson,
        screenHeight: window.innerHeight - footerHeight,
        lang: "my",
        cameraPreviewOn: false,
        library: {
            searchQuery: '',
            selectedTag: 'My Songs',
            data: null,
            loading: false,
            hasMore: true,
            page: 1,
            scrollPos: undefined,
            refresh: false
        },
        publicSearch: {
            searchQuery: '',
            selectedTag: 'Title',
            data: null,
            loading: false,
            hasMore: true,
            page: 1,
            scrollPos: undefined,
        },
        notiLists: null,
        collections: undefined, //for home screen
        artists: undefined, //for home screen
        latestSongs: undefined, //for home screen
        profile: undefined // for profile
    });

    const alertTimeoutRef = useRef<NodeJS.Timeout | null>(null);

    const [alert, setAlert] = useState<{ type: AlertType; text: string; visible: boolean } | null>(null);

    const [playlistOn, setPlaylistOn] = useState(false);
    const [playlistEditModeOn, setPlaylistEditModeOn] = useState(false);
    const [playlistSongId, setPlaylistSongId] = useState<number | null>(null);
    const [playlistChordId, setPlaylistChordId] = useState<number | null>(null);
    const [playlistId, setPlaylistId] = useState<number | undefined>(undefined);

    const showPlaylist = (songId?: number, chordId?: number, editModeOn?: boolean, playlistId?: number) => {
        setPlaylistOn(true);
        if(editModeOn !== undefined) setPlaylistEditModeOn(editModeOn);
        if(songId) setPlaylistSongId(songId);
        if(chordId) setPlaylistChordId(chordId);
        if(playlistId) setPlaylistId(playlistId);
    };

    const closePlaylist = () => {
        setPlaylistOn(false);
    }

    const showAlert = (type: AlertType, text: string, duration = 3000) => {
        if (alertTimeoutRef.current) {
            clearTimeout(alertTimeoutRef.current);
        }

        setAlert({ type, text, visible: true });

        alertTimeoutRef.current = setTimeout(() => {
            closeAlert();
        }, duration);
    };

    const closeAlert = () => {
        setAlert((prev) => (prev ? { ...prev, visible: false } : null));
    }

    useEffect(()=> {
        window.addEventListener('resize', () => {
            updateStore(pre => {
                return {...pre, screenHeight: window.innerHeight - footerHeight}
            })
        });
    },[])

    useEffect(()=> {
        const text  = store.lang === 'my' ? unicodeJson : enJson;
        updateStoreFunc('text',text);
    },[store.lang])

    const updateStoreFunc : storeType['updateStoreFunc']  = (storeKey, storeValue, nestedValue, cb) => {
        if(cb) updateStore(cb);
        updateStore((pre) => {
            if (nestedValue !== undefined) {
                return {
                    ...pre,
                    [storeKey as any]: {
                        ...(pre as any)[storeKey],
                        ...{[storeValue as any]: nestedValue},
                    },
                };
            } else {
                return { ...pre, [storeKey as any] : storeValue };
            }
        });
    }

    return (
        <storeContext.Provider value={{store,updateStoreFunc, showAlert, showPlaylist, playlistOn}}>
            {children}
            {alert && <Alert type={alert.type} text={alert.text} visible={alert.visible} onClose={closeAlert} />}
            <PlaylistDrawer
                isOpen={playlistOn}
                onClose={closePlaylist}
                songId={playlistSongId}
                editMode={playlistEditModeOn}
                chordId={playlistChordId}
                playlistId={playlistId}
            />
        </storeContext.Provider>
    );
});


export const useStore = () => {
    const context = useContext(storeContext);
    if (!context) {
      throw new Error('useStore must be used within an StoreProvider');
    }
    return context as storeType
};
