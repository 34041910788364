import { useEffect, useState } from 'react';
import { Share } from '@capacitor/share';
import {CameraPreviewOptions, CameraPreview} from '@capacitor-community/camera-preview';
// import { ScreenRecorder } from '@srikant-kumar/capacitor-screen-recorder';
import { Camera } from '@capacitor/camera';
import { Filesystem } from '@capacitor/filesystem';
import { Modal } from 'antd';
import { AndroidSettings, NativeSettings } from 'capacitor-native-settings';

import videoDarkPlurImg from '../../assets/images/video-dark-blur.png';
import videoLightPlurImg from '../../assets/images/video-light-blur.png';
import guitarLogo from '../../assets/images/camera-guitar-logo.png';
import { useStore } from '../../store';
import CustomButton from './CustomButton';
import config from '../../config';
import { KeepAwake } from '@capacitor-community/keep-awake';

export default function VideoCapture({lightTheme} : {lightTheme: boolean}) {
  const {store: {cameraPreviewOn, text}, updateStoreFunc} = useStore();
  const [recording, setRecording] = useState(false);


  const cameraStart = async() => {
    const cameraPreviewOptions: CameraPreviewOptions = {
      position: 'front',
      height: config.videoHeight,
      // y: 55,
      toBack: true,
      enableHighResolution: true
    };
    try{
      await CameraPreview.start(cameraPreviewOptions);
    }catch(err: any){
      updateStoreFunc('cameraPreviewOn',false);
    }
  }

  const cameraStop = () => {
    CameraPreview.stop();
  }

  const checkCameraPermissionAndStart = async () => {
    const {camera} = await Camera.checkPermissions();
    if(camera === 'denied'){
      goToSetting('camera');
      updateStoreFunc('cameraPreviewOn',false);
      return;
    }

    cameraStart();
  }

  const onRecordingComplete = async (filePath: string, fileName: string) => {
    Modal.confirm({
      title: text["Share Recording?"],
      content: text["The recording has been saved in the /Movies directory. Would you like to share it now?"],
      okText: text["Share"],
      cancelText: text["No"],
      centered: true,
      onOk() {
        shareRecording(filePath);
      },
    });
  }

  const shareRecording = async (filePath: string) => {
    const {publicStorage} = await Filesystem.checkPermissions();
      
    if(publicStorage === 'denied'){
      goToSetting('storage');
      return;
    }

    let requestedStatus;

    if(publicStorage !== 'granted') {
        requestedStatus = await Filesystem.requestPermissions();
        if(requestedStatus?.publicStorage !== "granted") return;
    }

    Share.share({
      url: "file:///" + filePath,
    });
  }

  const goToSetting = (v: string) => {
    let content : string = "";
    if(v === 'storage'){
      content = text['Please allow the Storage Permission in the app settings to proceed'];
    }

    if(v === 'camera'){
      content = text['Please allow the Camera Permission in the app settings to proceed'];
    }

    if(v === 'audio'){
      content = text['Please allow the Microphone Permission in the app settings to proceed'];
    }
  
    Modal.confirm({
      title: text['Go To Setting'],
      content: content,
      okText: "Setting",
      cancelText: "Cancel",
      centered: true,
      onOk() {
        if(v === 'storage' || v === 'camera' || v === 'audio'){
          NativeSettings.openAndroid({
            option: AndroidSettings.ApplicationDetails,
          });
        }
      },
    });
  }

  const handleRecordClick = async() => {
    setRecording(pre => !pre)
  }

  const handleRecord = async () => {
    if(recording) {
      try{
        // await ScreenRecorder.start({});
      }catch(err: any){
        if(err.message === 'Denied'){ // it means user chose 'Deny and don't ask again'
          goToSetting('audio');
        }
        setRecording(false);
      }
    }else{
      // ScreenRecorder.stop({});
    }
  }

  useEffect(()=> {
    if(cameraPreviewOn){
      checkCameraPermissionAndStart();
    }else{
      cameraStop();
    }
  },[cameraPreviewOn])

  const awake = async() => {
    const {isKeptAwake} = await KeepAwake.isKeptAwake();
    if(recording){
      if(!isKeptAwake) KeepAwake.keepAwake();
    }else{
      if(isKeptAwake) KeepAwake.allowSleep();
    }
  }

  useEffect(()=> {
    awake();
    handleRecord();
  },[recording])

  useEffect(()=> {
    // const recordingStartedListener = ScreenRecorder.addListener('onRecordingStarted', (data: any): any => {
    //   console.log("started",data);
    // });

    // const recordingCompletedListener  = ScreenRecorder.addListener('onRecordingComplete', (data: any): any => {
    //   const filePath = data.file_path;
    //   const fileName = data.file_name;
    //   onRecordingComplete(filePath, fileName);
    // });

    // const recordingErrorListener = ScreenRecorder.addListener('onRecordingError', (data: any): any => {
    //   setRecording(false);
    // });

    return () => {
      // recordingStartedListener.remove();
      // recordingCompletedListener.remove();
      // recordingErrorListener.remove();
      cameraStop();
      updateStoreFunc('cameraPreviewOn', false);
    }
  }, [])

  const blurPos = config.videoHeight - 100 + "px";

  const blurImg = lightTheme ? videoLightPlurImg : videoDarkPlurImg

  const blur = () => {
    return (
      <div>
        {
          cameraPreviewOn && 
          <div>
            <img style={{position: "fixed",top: "350px", left: "10px", zIndex: 1000}} src={guitarLogo}/>
            <CustomButton active={false} style={{position: 'fixed', right: '10px', top: '350px', zIndex: 1000,backgroundColor: recording? "#FF3D00" : "transparent",borderWidth: "0.2px", borderColor: "white", borderStyle: "solid", paddingLeft: "35px",paddingRight: "35px"}}
            onClick={handleRecordClick}>
              {recording ? "Stop" : "Record"}
            </CustomButton>
            <div style={{position: "fixed",top: blurPos, left: "-100px", width: "100%"}}>
              <img src={blurImg}/>
            </div>
          </div>
        }
      </div>
    )
  }

  return (
    <div>
        {blur()}
    </div>
  )
}
